import React from 'react';
import Helmet from 'react-helmet';

import GlobalStyles from './GlobalStyles';
import SiteHeader from './SiteHeader';

import 'bootstrap/dist/css/bootstrap.min.css';

const Layout = ({ children }) => (
  <main>
    <Helmet>
      <title>Advice</title>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap" rel="stylesheet" />
    </Helmet>

    <SiteHeader />

    {children}

    <GlobalStyles />
  </main>
);



export default Layout;