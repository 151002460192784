import { useStaticQuery, graphql } from "gatsby";

const useSources = () => {
  const data = useStaticQuery(graphql`
  query AllSourceQuery {
    allSourceJson {
      nodes {
        appid
        assetSuffix
        brandHeader
        description
        id
        journeyId
        phoneNo
      }
    }
  }
  `);
  return data?.allSourceJson.nodes.reduce((acc, curr) => ({
    ...acc,
    [curr["appid"]]: curr
  }), {});
};

export default useSources;
