import { graphql, useStaticQuery } from 'gatsby';


const useJourney = () => {

  const data = useStaticQuery(graphql`

    query JourneyAndSurveyConfigQuery {
      allSurveyConfigJson {
        nodes {
          surveyJson {
            showNavigationButtons
            showPrevButton
            showCompletedPage
            showQuestionNumbers
            goNextPageAutomatic
            requiredText
            triggers {
              expression
              type
              setValue
              setToName
            }
            pages {
              name
              questionTitleLocation
              title
              description
              elements {
                choices {
                  text
                  value
                }
                validators {
                  expression
                  regex
                  text
                  type
                }
                visible
                type
                titleLocation
                step
                title
                placeHolder
                requiredErrorText
                name
                otherText
                minWidth
                min
                maxLength
                max
                isRequired
                inputType
                indent
                html
              }
            }
          }
          behaviour {
            manualNext
          }
          surveyId
        }
      }

      allJourneyJson {
        nodes {
          journeyId
          defaultAppId
          hostNames
          isDefault
          oneTrustId
          liveChatStreamId
          liveChatTrackerStreamId
          gtmId
          gtmAuth
          gtmEnv
          surveyId
          useData8
          data8Type
          data8ApiKey
          meta {
            complaintsURL
            brandHeader
            feesURL
            privacyURL
          }
          headerScripts {
            name
            content
          }
        }
      }
    }`
  );
  const defaultSurveyConfig = data?.allSurveyConfigJson?.nodes?.find(survey => survey === "default");

  return data?.allJourneyJson?.nodes?.map(journey => {
    const surveyConfig = data.allSurveyConfigJson.nodes.find(survey => survey.surveyId === journey.surveyId);
    return {
      ...journey,
      surveyConfig: surveyConfig || defaultSurveyConfig
    }
  });
}

export default useJourney