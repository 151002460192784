import Cookies from 'js-cookie';

import { safeParse } from './json';

const WL_CONTEXT_COOKIE_EXPIRY_DAYS = 3;
const wlContext = {
    get: () => safeParse(Cookies.get(`wlContext`)),
    set: (value) => Cookies.set(`wlContext`, value, { expires: WL_CONTEXT_COOKIE_EXPIRY_DAYS }) 
}

const service = {
    wlContext
};

export default service;