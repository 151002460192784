
const INELIGIBLE_SCORES = [ 'RedIneligible', 'RedUnlikely', "Ignore" ];
export const fsProductRecommendations = (payload) => {
    Object.keys(payload.report.scores).forEach((solutionName) => {
        window?.FS?.event('solution-outcome', {
            solution_str: solutionName,
            score_str: payload.report.scores[solutionName]
        });
    });

    if(!Object.values(payload.report.scores).some((score) => INELIGIBLE_SCORES.includes(score))) {
        window?.FS?.event('solution-ineligible', {});
    }
}
