import CONFIG from "../utils/config";

export function init(id, env) {
  const postfix = env === "prod" ? "" : "-test";

  const consentScript = document.createElement("script");
  consentScript.src = `https://cdn-ukwest.onetrust.com/consent/${id}${postfix}/OtAutoBlock.js`;
  document.head.insertBefore(consentScript, document.head.firstChild);

  const oneTrustScript = document.createElement("script");
  oneTrustScript.src =
    "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js";
  oneTrustScript.type = "text/javascript";
  oneTrustScript.charset = "UTF-8";
  oneTrustScript.setAttribute("data-domain-script", `${id}${postfix}`);
  document.head.insertBefore(oneTrustScript, consentScript);

  window.OptanonWrapper = () => {
    window.dataLayer.push({ event: "OneTrustGroupsUpdated" });
  };
}

export const initOneTrust = (id) => {
  init(id, CONFIG.ENV);
};
