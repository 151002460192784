import React from "react";

import Layout from './src/components/Layout/'
import WLThemeProvider from './src/Theme';

import { JourneyContextProvider } from './src/components/Contexts/JourneyContext/JourneyContext';

import eventBus from './src/services/eventBus';

import events from './src/services/eventBus/events';

const wrapRootElement = ({ element }) => {
    eventBus.init(events);
    return (
        <JourneyContextProvider>
            <WLThemeProvider>
                <Layout>
                    {element}
                </Layout>
            </WLThemeProvider>
        </JourneyContextProvider>
    )
}

export { wrapRootElement };