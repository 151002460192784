import stepComplete from './step-complete';
import submission from './submission';
import journeyAppIdSet from './journey-appid-set';
import startGtmTracking from './start-gtm-tracking';
import stopGtmTracking from './stop-gtm-tracking';

const events = [
    journeyAppIdSet, stepComplete, submission, startGtmTracking, stopGtmTracking
].reduce((acc, curr) => ({ ...acc, [curr.name]: curr.subscribers }), {});

export default events;