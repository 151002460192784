import React from 'react';
import { ThemeProvider } from "styled-components";

// theme hardcoded for now
export const theme = {
  colors: {
    blue: "#00ade6",
    blueLight: "#2d9de1",
    blueDark: "#252f61",
    black: "#000",
    white: "#fff",
    grey1: "#979797",
    grey2: "#f9f9f9",
    grey3: "#ddd",
    grey4: "#f2f2f2",
    greyDark: "#4a4a4a",
    red: "#ff0000",
    redDark:"#b01e00",
    greenPale: "#a4da9d",
    green:"#53c200",
    greenDark:"#008800",
    orange: "#ff7800",
    orangeDark: "#ef400e"
  },
  spacing: {
    base: 6
  },
  typography: {
    default: "'Roboto', sans-serif;"
  }
}

const WLThemeProvider = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default WLThemeProvider;