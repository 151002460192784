export const init = () => {
  const jQuery = document.createElement("script");
  jQuery.type = "text/javascript";
  jQuery.src = "//ajax.googleapis.com/ajax/libs/jquery/1.7.2/jquery.min.js";

  document.head.insertBefore(jQuery, document.head.firstChild);

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "//apps.commbox.io//Scripts/connect.js";
  document.head.insertBefore(script, jQuery);

  return script;
}

export const initLiveChat = (streamId, trackerId) => {
  const script = init();
  
  script.addEventListener("load", () => {
    initEvent(streamId, trackerId);
  });
  return script;
};

function initEvent(streamId, trackerId) {
  if (typeof $ == "undefined" || typeof BY == "undefined") {
    return setTimeout(initEvent, 500);
  }

  window.BY.chat({
    baseDomain: "commbox.io/",
    appsSubDomain: "apps",
    streamId: streamId,
    trackerStreamId: trackerId,
  });
}

