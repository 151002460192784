//export const push = (payload) => window?.dataLayer?.push(payload);
export const push = (payload) => {
    if(!window?.dataLayer) {
        window.dataLayer = [];
    }
    window.dataLayer.push(payload);
}

const gtm = { push }

export default gtm;