import { stepCompleteToGtm } from '../subscribers/gtm';

const event = {
  name: 'step-complete',
  subscribers: [ 
    stepCompleteToGtm,
  ]
}


export default event