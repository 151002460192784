import { submissionToGtm } from '../subscribers/gtm';
import { fsProductRecommendations } from '../subscribers/fullStory';


const event = {
  name: 'submission',
  subscribers: [
    submissionToGtm,
    fsProductRecommendations
  ]
}

export default event