

export function renderScriptString(name, content, element = document.body, callback) {
  try {
    const existingScript = document.getElementById(name);
    if (!existingScript) {
      const script = document.createElement('script');
      script.innerHTML = content;
      script.id = name;
      element.appendChild(script);
      script.onload = () => callback && callback();
    }
    if(existingScript && callback) callback();

  } catch(e) {
    console.error(`script: ${name} failed to render :: ${e}`);  
  } 
} 

export function renderScriptFromSrc(src, element) {
  try {
      const script = document.createElement('script');
      script.src = src;
      element.appendChild(script);
  } catch(e) {
    console.error(`script: ${src} failed to render :: ${e}`);  
  } 
}


  