import React from 'react';

import queryString from 'query-string';
import cookies from '../../../utils/cookies';

import { isSSR } from '../../../utils/renderContext';
import { publishEvent } from '../../../services/eventBus/';

import useJourney from '../../../hooks/data/useJourney';
import useSources from '../../../hooks/data/useSources';

const JourneyContext = React.createContext({
	source: null,
	journey: null
});

/**
 * Allows direct hook access to the journey context
 */
export const useJourneyContext = () => React.useContext(JourneyContext);

/**
 * Custom context provider
 */
export const JourneyContextProvider = ({ children }) => {
	const journeys = useJourney();
	const sources = useSources();
	let wlContextCookie;
	let sourceContext = sources.cf; // this shouldnt be needed now but leaving as it is used by
	let journeyContextData = journeys.find(
		(journey) => journey.journeyId === sourceContext.journeyId
	);
	if (!isSSR()) {
		// grab appid from query params passed in
		const queryParams = queryString.parse(window.location.search);
		let foundAppId = queryParams.appId || queryParams.appid;
		if (!foundAppId) {
			wlContextCookie = cookies.wlContext.get();
			foundAppId = wlContextCookie?.appId;
		}
		foundAppId = parseAppId(foundAppId);
		sourceContext = getContextForAppId(foundAppId, sources, journeys);
		journeyContextData = getJourneyContextForSource(
			journeys,
			sourceContext,
			journeyContextData
		);
		publishEvent('journey-appid-set', {
			...journeyContextData,
			appId: sourceContext.appIdRoot
		});
		sourceContext.cid = queryParams?.cid || wlContextCookie?.cid;
		sourceContext.campaignID =
			queryParams?.campaignID || queryParams?.campaignId ||  queryParams?.campaignid || wlContextCookie?.campaignID;
	}
	cookies.wlContext.set(sourceContext);
	JourneyContext.source = sourceContext;
	JourneyContext.journey = journeyContextData;

	return (
		<JourneyContext.Provider value={JourneyContext}>
			{sourceContext && journeys ? children : null}
		</JourneyContext.Provider>
	);
};

/**
 * parses idString to check for _
 * @param idString
 * @returns {string}
 */
export function parseAppId(idString) {
	if (idString !== undefined && !idString.includes('_')) {
		idString = `${idString}_`;
	}
	return idString;
}

/**
 * Logic for deciding Journey context from passed in appId
 */
export function getContextForAppId(idString, sources, journeys) {
	// parse important but to set context.
	let appId = idString;
	let appIdRoot;
	let sourceContext;
	try {
		appIdRoot = idString.split('_')[0];
		if (typeof sources[appIdRoot] === 'undefined') {
			throw new Error('unregistered appid');
		}
	} catch (e) {
		// use defaults hostname -> fallback default
		let defaultJourney = journeys.find((journey) =>
			journey.hostNames.includes(window.location.hostname)
		);
		if (typeof defaultJourney == 'undefined') {
			defaultJourney = journeys.find((journey) => journey.isDefault);
		}
		const defaultAppId = defaultJourney.defaultAppId;
		appId = `${defaultAppId}${idString || ''}`;
		appIdRoot = defaultAppId.replace('_', '');
	}
	sourceContext = sources[appIdRoot];
	sourceContext.appId = appId;
	sourceContext.appIdRoot = `${appIdRoot}_`;
	return sourceContext;
}

/* get Journey for source else default */
function getJourneyContextForSource(journeys, sourceContext, defaultJourney) {
	const journey = journeys.find(
		(journey) => journey.journeyId === sourceContext.journeyId
	);
	if (!journey) {
		return defaultJourney;
	}
	return journey;
}
