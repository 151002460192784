import { renderScriptString, renderScriptFromSrc } from "../../../utils/renderScript"

export function renderScripts(payload) {
  payload.headerScripts?.forEach(({ name, content }) =>
    renderScriptString(name, content, document.header));
}

export function renderData8Script(payload) {
  if(payload.useData8 && payload.data8ApiKey != null) {
    renderScriptFromSrc(`https://webservices.data-8.co.uk/Javascript/Loader.ashx?key=${payload.data8ApiKey}&load=PhoneValidation`, document.head);
    renderScriptFromSrc(`https://webservices.data-8.co.uk/Javascript/Loader.ashx?key=${payload.data8ApiKey}&load=EmailValidation`, document.head);
  }
}


