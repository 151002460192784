import React from 'react';
import styled from 'styled-components'
import { useJourneyContext } from '../../Contexts/JourneyContext/JourneyContext';
import RenderOnClientOnly from '../../utils/RenderOnClientOnly'


export const SiteHeader = () => {
  const { journey, source } = useJourneyContext();
  if(!journey.meta.brandHeader || source.brandHeader === 'none') return null;
  
  return (
    <RenderOnClientOnly>
      <SiteHeaderLayout
        assetSuffix={source.assetSuffix}
        brandHeader={source.brandHeader || journey.meta.brandHeader}
      />
    </RenderOnClientOnly>
  );
}

export const SiteHeaderLayout = ({ assetSuffix, brandHeader }) => (
  <Header>

    <LogoWrap>
      <img src={`../../../../mainLogo-${assetSuffix}.png`} height="30" width={175} />

      {brandHeader === 'partner' && (
        <>
          <PartnerLogo brandName={assetSuffix} />
        </>
      )}
    </LogoWrap>

    {brandHeader === 'partner' && (<HeaderTitle>In partnership with</HeaderTitle>)}

  </Header>
);


const PartnerLogo = ({ brandName }) => (
  <img src={`../../../partner-logo-${brandName}.png`} height="30" alt={brandName} />
);

const Header = styled.header`
  padding-top: ${props => props.theme.spacing.base * 2}px;
  padding-right: ${props => props.theme.spacing.base * 3}px;
  padding-bottom: ${props => props.theme.spacing.base * 2}px;
  padding-left: ${props => props.theme.spacing.base * 3}px;

  @media(min-width: 768px) {
    padding-top: ${props => props.theme.spacing.base * 5}px;
    padding-right: ${props => props.theme.spacing.base * 7}px;
    padding-bottom: ${props => props.theme.spacing.base * 3}px;
    padding-left: ${props => props.theme.spacing.base * 7}px;
  }

  margin-bottom: ${props => props.theme.spacing.base * 0.667}px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

const LogoWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderTitle = styled.p`
  width: 100%;

  margin: 0;
  margin-top: 6px;

  color: rgba(0, 0, 0, 0.65);

  text-align: center;

  @media(min-width: 768px) {
    text-align: right;
    margin: 5px 0 0;
  } 
`;

export default SiteHeader;
