import { appIdSetToGtm } from '../subscribers/gtm';
import {appIdSetLiveChat} from "../subscribers/liveChat";
import { appIdSetOneTrust } from "../subscribers/one-trust";
import { renderScripts, renderData8Script } from "../subscribers/configScripts" 
const event = {
  name: 'journey-appid-set',
  subscribers: [ 
    appIdSetToGtm,
    appIdSetLiveChat, 
    appIdSetOneTrust,
    renderScripts,
    renderData8Script
  ]
}


export default event