

export const safeParse = (jsonString) => {
    try {
        if(typeof jsonString === 'undefined') {
            return;
        }
        return JSON.parse(jsonString);
    } catch {
        return;
    }
}

const service = {
    safeParse
};

export default service;