// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advice-js": () => import("./../../../src/pages/advice.js" /* webpackChunkName: "component---src-pages-advice-js" */),
  "component---src-pages-complete-js": () => import("./../../../src/pages/complete.js" /* webpackChunkName: "component---src-pages-complete-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-solution-info-js": () => import("./../../../src/templates/solution-info.js" /* webpackChunkName: "component---src-templates-solution-info-js" */),
  "component---src-templates-solution-story-js": () => import("./../../../src/templates/solution-story.js" /* webpackChunkName: "component---src-templates-solution-story-js" */)
}

