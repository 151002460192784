let eventLog = {};
let subscriptions = {};

export const init = (initialSubscriptions = {}) => {
  eventLog = Object.keys(initialSubscriptions)
    .reduce((events, eventName) => ({ ...events, [eventName]: [] }), { allEvents: [] });
  subscriptions = initialSubscriptions;
}

export const publishEvent = (name, payload) => {
  subscriptions[name]?.forEach(subscriber => {
    try {
      subscriber(payload);
    } catch(e) {
      console?.warn(`error in eventBus - ${name}`);
    }
  });
  const result = { name, payload, time: Date.now() };

  eventLog = {
    ...eventLog,
    allEvents: [ ...eventLog?.allEvents ?? [], result ],
    [name]: [ ...(eventLog[name] || []), result ]
  }
};

// this has been kept fairly basic, a sub method may be needed down the line.
const eventBus = {
  publishEvent,
  eventHistory: () => eventLog,
  init
};

export default eventBus;