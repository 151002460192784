import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html, body, main, #___gatsby {
    min-height: 100vh;
    color: ${props => props.theme.colors.black}
  }

  #gatsby-focus-wrapper {
    height: 100%;
  }

  body {
    font-family: ${props => props.theme.typography.default};
    margin: 0;
    background: ${props => props.theme.spacing.grey2};
  }

  
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /*  Helper classes  */

  .text-center { text-align: center; }

  .position-relative { position: relative; }

  .hidden { display: none !important; }

  .fwg-box-shadow { box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); }

  .bg-grey { background-color:${props => props.theme.colors.grey2} };

  /**
   * probably dont need these
   */
  
  .text-medium { font-size:18px; }
  .font-weight-medium { font-weight: 500; }
  .line-height-reduced { line-height: 1.3; }



  /* One trust style fix workaround */
  #onetrust-consent-sdk  .ot-slide-out-left {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
`;

export default GlobalStyles;