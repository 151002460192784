const map = new Map();

export function init(id, auth, containerEnv, name = "dataLayer") {
  if (map.has(id)) {
    if (map.get(id).has(name)) {
      console.warn(
        `Id and name pair already registered id: ${id} name: ${name}`
      );
      return;
    }
  }

  window[name] = window[name] || [];
  window[name].push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });

  const dl = name !== "dataLayer" ? `&l=${name}` : "";

  const script = createScript(id, dl, auth, containerEnv);
  const { iframe, noscript } = createIframe(id, auth, containerEnv);

  if (map.has(id)) {
    map.get(id).set(name, { script, iframe, noscript });
  } else {
    const m = new Map();
    m.set(name, { script, iframe, noscript });
    map.set(id, m);
  }

  return {
    script,
    iframe,
  };
}

export function off(id, name = "dataLayer") {
  const idGroup = map.get(id);
  if (!idGroup) return;
  const current = idGroup.get(name);
  if (!current) return;

  window[name].push("consent", "update", { analytics_storage: "denied" });

  current.script.remove();
  current.noscript.remove();
}

function createScript(id, dl, auth, containerEnv) {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${id}${dl}&gtm_auth=${auth}&gtm_preview=${containerEnv}&gtm_cookies_win=x`;

  document.head.insertBefore(script, document.head.firstChild);

  return script;
}

function createIframe(id, auth, containerEnv) {
  const iframe = document.createElement("iframe");
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}&gtm_auth=${auth}&gtm_preview=${containerEnv}&gtm_cookies_win=x`;
  iframe.style.width = "0";
  iframe.style.height = "0";
  iframe.style.display = "none";
  iframe.style.visibility = "hidden";

  const noScript = document.createElement("noscript");
  noScript.appendChild(iframe);

  document.body.insertBefore(noScript, document.body.firstChild);

  return { iframe, noscript: noScript };
}
